import React, { useEffect, useState } from "react";
import { Modal, Button, DatePicker } from "antd";
import Wrapper from "./style";
import { CSVLink } from "react-csv";
import { NotificationManager } from "react-notifications";
import ExportBulkQR from "./exportBulkCards";
import { customBaseUrl, httpGet, httpPost } from "../../store/http";
import { hideLoader, showLoader } from "../../utils/loader";
import Swal from "sweetalert2";

const ExportQr = ({ showModal, hideModal, dataList, adminUsers }) => {
  const [data, setData] = useState({});
  const [filteredData, setFilteredData] = useState([]);
  const [qrData, setQrData] = useState([])
  const [showQrData, setShowQrData] = useState(false);
  const [email, setEmail] = useState('')
  const profileName = localStorage.getItem('fullName');

  const handleFilterOperation = () => {
   // return alert(JSON.stringify(data));
    if (data.startDate && data.endDate) {
      console.log(data);
      const filteredData = dataList.filter(({createdAt}) => {
        var date = new Date(createdAt);
        return date > new Date(data.startDate) && date < new Date(data.endDate);
      });
      console.log(filteredData);
      setFilteredData(filteredData);

    } else {
      NotificationManager.error("Please select date range");
    }
  };
  function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
}



const handleExport = async() => {
  console.log({count: data?.count, email});
  showLoader();
  if(!data?.count || !email) return Swal.fire('error','Invalid input');
  const postData = {
    noOfQrCode: data?.count,
    adminEmail: email,
  }
  let d = new Date();
    const res = await httpPost(`${customBaseUrl.logUrl}/api/v1/requestlog/create`,
    {
      payload: JSON.stringify(postData),
      "requestId": `REQ${d.getTime()}`,
      "requestType": "exportQRCode",
      customerName: profileName
    } )

    if (res?.status) {
      Swal.fire(res?.message);
      hideLoader();
      hideModal(false);
    } else {
      Swal.fire(res?.message);
      hideLoader();
    }

  //showLoader();
  /*const response = await httpPost(
    `${customBaseUrl.qrCodeUrl}/api/v1/qrcode/export-qrcode?page=0&size=${data?.count}`
  );
  if(response.length>0){    
    setQrData(response);
    setShowQrData(true);
  }*/
}

useEffect(()=> {
  console.log('admin users', adminUsers);
}, []);
  return (
    <Modal
      visible={showModal}
      closable={false}
      footer={null}
      centered
      onCancel={() => {
        hideModal(false);
      }}
      title=""
      bodyStyle={{
        padding: "23px 73px 44px 73px",
        borderRadius: "12px",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          width: "100%",
          marginBottom: "27px",
        }}
      >
        <div
          style={{
            cursor: "pointer",
          }}
          onClick={() => {
            hideModal(false);
          }}
        >
          X
        </div>
      </div>
      <Wrapper>
        <div className="title">Export Bulk Preprinted Cards</div>
        <div className="cw-content">
        <div className="input-content border-dark">
            <div className="label">Number of card to be exported</div>
            <input onChange={(e)=>setData({...data, count:e.target.value})}  name="" id="" className="border border-dark"/>
          </div>
          <div className="input-content border-dark">
            <div className="label">Admin Users</div>
            <select name="" id="" className="border border-dark"
              onChange={(e)=> setEmail(e.target.value)}
            >
              <option value="">Select Admin</option>
              {
                adminUsers?.map((e,i) => 
                  <option value={e.email}>
                    {`${e?.firstName} ${e?.lastName}`}
                  </option>
                )
              }
            </select>
          </div>
            {/*<div className="label">Start Date</div>
           
         <DatePicker   onChange={(e)=>{setData({ ...data, startDate: formatDate(e) })       }  } />

            <div className="label" style={{marginTop:20}}>End Date</div>
            
          <DatePicker   onChange={(e)=>{setData({ ...data, endDate: formatDate(e) })       }  } />
        */}
        </div>
      </Wrapper>

      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          marginTop: 50
        }}
      >
        {/*<CSVLink
          data={filteredData}
          style={{
            marginLeft: "auto",
            marginRight: "auto",
            background: "#FF4B01",
            color: "#fff",
            borderRadius: "3px",
            fontSize: "14px",
            lineHeight: "17px",
            display: "flex",
            alignItems: "center",
            padding: "10px 25px",
          }}
          onClick={(event, done) => {
            // event.preventDefault();
              
          }}
        >*/}
        <button style={{
            marginLeft: "auto",
            marginRight: "auto",
            background: "#FF4B01",
            color: "#fff",
            borderRadius: "3px",
            fontSize: "14px",
            lineHeight: "17px",
            display: "flex",
            alignItems: "center",
            padding: "10px 25px",
          }}
          // onClick={handleExport}
          >
            
          Export Bulk
        </button>
        {//</div></CSVLink>
        }
      </div>
      {
        showQrData &&
        <ExportBulkQR qrValue={qrData} modalState={showQrData} modalCloseAction={setShowQrData} />

      }
    </Modal>
  );
};

export default ExportQr;
